import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';

@Component
export class FileNameconfirmation extends Vue {
    private objScreenText: ScreenText = new ScreenText();

    @Prop()
    title! : string;

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    closePopup() {
      this.$emit(APP_CONST.CLOSE);
    }
    fileReplace() {
      this.$emit('replaceFile');
    }
    keepBothFile() {
      this.$emit('keepBothFile');
    }
    
   

}